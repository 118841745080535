import React from "react"
import SEO from "../components/Seo"
// import Video from "../components/Video"
import styled from "@emotion/styled"
import YouTube from "react-youtube"
// import Img from "gatsby-image"
// import { Link } from "gatsby"

export default () => {
  return (
    <>
      <SEO
        title="Yarite, a simple and personal alternative to complex CRM"
        description="Yarite reminds you to keep in touch with those who matter most."
      />
      <Wrapper>
        <h1>
          <img src="/yarite.svg" width="280" alt="Yarite logo" />
        </h1>
        <Flex>
          <Content>
            <h2 style={{ paddingBottom: "1rem" }}>
              Keep in touch with those who matter most
            </h2>
            <p style={{ color: "rgba(255,255,255,.6)" }}>
              Yarite is a simple, minimal, and personal tool that reminds you to
              keep in touch with those who matter most.
            </p>
            <p style={{ color: "rgba(255,255,255,.6)" }}>
              Friends, family, or work contacts. An easy and effective
              alternative to complex CRM software, so you can feel confident
              that you haven’t forgotten about anyone important.
            </p>
            <p style={{ color: "rgba(255,255,255,.6)" }}>
              As easy as that, get periodic reminders to reach out.
            </p>

            <FormWrapper>
              <p>GET EARLY ACCESS</p>
              <Form
                action="https://api.producthunt.com/widgets/upcoming/v1/upcoming/yarite/forms"
                method="post"
                id="ph-email-form"
                name="ph-email-form"
                target="_blank"
              >
                <InputEmail
                  type="email"
                  defaultValue=""
                  name="email"
                  id="ph-email"
                  placeholder="Email Address"
                  required
                />
                <InputSubmit
                  type="submit"
                  defaultValue="Subscribe"
                  name="subscribe"
                  id="ph-subscribe-button"
                />
              </Form>
            </FormWrapper>
          </Content>

          <VideoWrapper>
            <StyledYouTube
              videoId="yUT_QHM82M4"
              opts={{
                playerVars: {
                  modestbranding: true,
                  rel: 0,
                  showinfo: 0,
                  controls: 0,
                  iv_load_policy: 3,
                },
              }}
            ></StyledYouTube>
          </VideoWrapper>
        </Flex>
        <StFooter>
          © {new Date().getFullYear()}, Yarite, {"  "}
          <a sx={{}} href="https://ourfir.es">
            by Ourfires
          </a>
        </StFooter>
      </Wrapper>
    </>
  )
}

// const QueryBreakpoints = createMedia({
//   breakpoints: {
//     sm: 0,
//     md: 768,
//     lg: 1024,
//     xl: 1192,
//   },
// })
// 1150

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto 0;
  max-width: 100rem;
  /* height: 100%; */
  padding: 4rem 2rem 0;

  @media only screen and (max-width: 1150px) {
    max-width: 680px;
  }
`

const Flex = styled.div`
  flex-grow: 1;

  position: relative;
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1150px) {
    flex-grow: 0;
    flex-direction: column;
  }
`
const Content = styled.div`
  max-width: 40rem;
  padding-right: 1rem;
`
const VideoWrapper = styled.div`
  position: relative;
  height: 0;
  width: 50%;
  padding-bottom: 37.5%;
  position: relative;

  @media only screen and (max-width: 1150px) {
    width: 100%;
    padding-bottom: 75%;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`

const StyledYouTube = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const FormWrapper = styled.div`
  padding-top: 3rem;
`

const Form = styled.form`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
`

const InputEmail = styled.input`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 13px;
  padding: 8px;
`

const InputSubmit = styled.input`
  margin-left: 10px;
  background: #5639cc;
  border-radius: 3px;
  border: 1px solid #3013aa;
  box-sizing: border-box;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 11px;
  font-weight: 600;
  height: 34px;
  letter-spacing: 0.3px;
  line-height: 16px;
  padding: 0 13px;
  text-transform: uppercase;
`

const StFooter = styled.footer`
  position: relative;
  width: 100%;
  height: 30px;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  a {
    color: rgba(255, 255, 255, 0.5);
  }
  a:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`
